<template>
  <v-row class="mt-5">
    <v-col
      cols="10"
      sm="10"
      md="6"
      lg4
      class="mx-auto"
    >
      <span v-if="!warrant">
        <v-text-field
          v-model="warrantCode"
          outlined
          :label="$t('Warrant code')"
          :placeholder="$t('Warrant code')"
          hide-details
          class="mb-6"
        ></v-text-field>
        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-6 py-6"
          @click="findWarrant()"
        >
          Potvrdi
        </v-btn>
      </span>

      <v-simple-table
        v-if="warrant"
        cols="12"
        class="mt-5 pa-2"
      >
        <tr>
          <th
            colspan="2"
            class="py-2"
          >
            Pregled statusa za nalog:
          </th>
        </tr>
        <tr>
          <td>
            Klijent
          </td>
          <td>
            {{ warrant.company.name }}
          </td>
        </tr>
        <tr>
          <td>
            Datum
          </td>
          <td>
            {{ warrant.warrant_date }}
          </td>
        </tr>
        <tr>
          <td>
            Status
          </td>
          <td>
            {{ $t(warrant.status) }}
          </td>
        </tr>
        <tr>
          <td>
            <v-btn
              to="/"
              class="primary pa-2"
            >
              {{ $t('forgotPassword.backToLogin') }}
            </v-btn>
          </td>
        </tr>
      </v-simple-table>
    </v-col>
    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Warrant not found') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>

import axiosIns from '@axios'

export default {
  name: 'WarrantStatusCheck',
  data() {
    return {
      warrantCode: null,
      warrant: null,
      snackbar: false,
    }
  },
  methods: {
    findWarrant() {
      axiosIns({
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        url: `/warrant-status-check/${this.warrantCode}`,
      })
        .then(res => {
          this.warrant = res.data.warrant
        })
        .catch(e => {
          this.snackbar = true
        })
    },
  },
}
</script>
